import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../logo.png";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { useAuthUser } from "@react-query-firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuthUser(["user"], auth);

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleSend = () => {
    const actionCodeSettings = {
      url: `http://${window.location.host}/login`,
      handleCodeInApp: true,
    };

    window.localStorage.setItem("emailForSignIn", email);

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        setEmailSent(true);
        console.log("email sent");
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      console.log("isSignInWithEmailLink");
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      } else {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            console.log({ result });
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");

            const token = window.localStorage.getItem("invoiceToken");

            if (token) {
              navigate(`/connect?token=${token}`);
            } else {
              navigate("/");
            }
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (!user.isLoading && user.data) {
      navigate("/");
    }
  });

  if (user.isLoading) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ marginTop: 2 }}>
        <CardHeader
          title={t("title")}
          avatar={<img src={logo} alt="" height={30} />}
        />
        <CardContent>
          {emailSent ? (
            <>
              <Typography>{t("checkEmail")}</Typography>
            </>
          ) : (
            <TextField
              fullWidth
              sx={{ marginTop: 1 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={t("email")}
              type="email"
            />
          )}
        </CardContent>
        {!emailSent && (
          <CardActions>
            <Button
              onClick={handleSend}
              disabled={emailSent}
              variant="contained"
              fullWidth
            >
              {t("sendLoginLink")}
            </Button>
          </CardActions>
        )}
      </Card>
    </Container>
  );
};
