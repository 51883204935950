import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Stack,
  Card,
  CardActions,
  CardHeader,
  CardContent,
} from "@mui/material";
import { auth, firebaseApp } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "@react-query-firebase/auth";
import { useTranslation } from "react-i18next";

export const Home: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const ref = useRef<any>(null);

  const { t } = useTranslation();

  const user = useAuthUser(["user"], auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isLoading && !user.data) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleGetToken = async () => {
    setSubmitting(true);
    const functions = getFunctions(firebaseApp);

    const callGetToken = httpsCallable(functions, "invoiceConnectMembership");

    const response = (await callGetToken({ requestType: "connect_2" })) as any;

    if (!ref.current) {
      return;
    }

    const { token, cardId } = response.data;

    ref.current.elements["token"].value = token;
    ref.current.elements["card_no1"].value = cardId;
    ref.current.elements["card_no2"].value = cardId;
    ref.current.submit();
  };

  return (
    <Container>
      <form
        method="POST"
        action="https://wwwtest.einvoice.nat.gov.tw/APCONSUMER/BTC103I/"
        ref={ref}
      >
        <Stack>
          <input type="hidden" id="card_ban" name="card_ban" value="52759308" />

          <input type="hidden" id="card_no1" name="card_no1" value={""} />
          <input type="hidden" id="card_no2" name="card_no2" value={""} />

          <input
            type="hidden"
            id="card_type"
            name="card_type"
            value={"RUoxNTI3"}
          />

          <input
            type="hidden"
            id="back_url"
            name="back_url"
            value="https://testeinvoice.qpos.me"
          />

          <input type="hidden" id="token" name="token" value="" />
        </Stack>
        <Stack spacing={2}>
          <Card>
            <CardHeader title={t("searchInvoice")} />
            <CardActions>
              <Button onClick={() => navigate("/search")} variant="outlined">
                {t("search")}
              </Button>
            </CardActions>
          </Card>
          <Card>
            <CardHeader title={t("connectWithEInvoice")} />
            <CardActions>
              <Button
                variant="outlined"
                onClick={handleGetToken}
                disabled={submitting}
              >
                {t("connect")}
              </Button>
            </CardActions>
          </Card>
        </Stack>
      </form>
    </Container>
  );
};
