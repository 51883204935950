import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "./components/Router";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import grey from "@mui/material/colors/grey";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const themeLight = createTheme({
    palette: {
      primary: grey
    },
  });

  return (
    <ThemeProvider theme={themeLight}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfvR8ccAAAAADV6BNITfaBxwKWlIK6g18osK5aB">
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Router />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

export default App;
