import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: {
          sellerName: "Seller name",
          title: "QUEUE MEMBER",
          home: "Home",
          search: "Search",
          searchInvoice: "Search invoice",
          invoiceNumber: "Invoice number",
          connectWithEInvoice: "Connect with eInvoice platform",
          connect: "Connect",
          companyNumber: "Company number",
          invoiceDate: "Invoice date",
          invoiceAmount: "Invoice amount",
          vehicleCategory: "Vehicle category",
          donationCode: "Donation code",
          invoiceStatus: "Invoice status",
          buyerName: "Buyer name",
          carrierId: "Carrier Id",
          chineseYear: "Chinese year",
          taxPeriod: "Tax period",
          randomNumber: "Random number (XXXX)",
          buyerId: "Buyer Id",
          c0401: "Open",
          c0501: "Cancelled",
          d0401: "Voided",
          product: "Product",
          quantity: "Quantity",
          totalPrice: "Total price",
          back: "Back",
          unitPrice: "Unit price",
          login: "Login",
          sendLoginLink: "Send login link",
          email: "Email address",
          close: "Close",
          checkEmail:
            "Please check your email to complete your login. If you did not receive a signin link, please check your spam filter or try again.",
          confirmConnecting:
            "Confirm connecting {{email}} with eInvoice platform?",
          confirm: "Confirm",
        },
      },
      "zh-TW": {
        translation: {
          taxPeriod: "發票期別",
          sellerName: "賣方名稱",
          companyNumber: "賣方統編",
          home: "我家",
          carrierId: "載具編號",
          searchInvoice: "電子發票查詢",
          chineseYear: "民國",
          search: "查詢",
          invoiceNumber: "發票號碼(XX12345678)",
          randomNumber: "隨機碼",
          invoiceDate: "日期",
          invoiceAmount: "金額",
          buyerId: "買方統編",
          invoiceStatus: "狀態",
          buyerName: "名稱",
          c0401: "開立",
          c0501: "作廢",
          d0401: "折讓",
          product: "商品",
          quantity: "數量",
          totalPrice: "合計",
          back: "返回",
          unitPrice: "單價",
          vehicleCategory: "載具類別",
          donationCode: "捐贈碼	",
          login: "登錄",
          sendLoginLink: "發送登錄鏈接",
          email: "電子郵件",
          close: "關閉",
          connectWithEInvoice: "歸戶電子發票整合服務平台",
          confirmConnecting:
            "確認 {{email}} 連結至電子發票平台",
          confirm: "確認",
          connect: "連接",
          checkEmail:
            "請確認您的電子信箱來完成登入，如果你沒有收到確認信件連結，請確認您垃圾郵件或者在嘗試一次。",
        },
      },
    },
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
