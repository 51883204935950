import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
  authDomain: "qpos.me",
  databaseURL: "https://queue-api.firebaseio.com",
  projectId: "queue-api",
  storageBucket: "queue-api.appspot.com",
  messagingSenderId: "1063204016925",
  appId: "1:1063204016925:web:ac56e1449881b0728487f4",
  measurementId: "G-D0BVXPXJGW",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const functions = getFunctions(firebaseApp);
if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const auth = getAuth(firebaseApp);
