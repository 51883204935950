import { httpsCallable } from "@firebase/functions";
import {
  MenuItem,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, useForm } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { functions } from "../firebase";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const SearchInvoice: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<any>(null);

  const params = useParams();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invoiceNumber: params.invoiceNumber || "",
      chineseYear: params.chineseYear || "110",
      randomNumber: params.randomNumber || "",
      taxPeriod: params.taxPeriod || "1",
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  console.log({ invoice });

  const onSubmit = async (values: any) => {
    setLoading(true);

    console.log({ errors });

    try {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("searchInvoice");

      const callSearchInvoice = httpsCallable(functions, "invoiceSearch");

      const result = (await callSearchInvoice({
        ...values,
        responseToken: token,
      })) as any;

      if (result.data.status === 0) {
        setInvoice(result.data.invoice);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <CircularProgress sx={{ alignSelf: "center", flex: 1 }} />
      </Box>
    );
  }

  if (invoice) {
    return (
      <Card>
        <CardHeader title={invoice.invoiceNumber} />
        <CardContent>
          <Table>
            <TableRow>
              <TableCell>{t("sellerName")}</TableCell>
              <TableCell align="right">{invoice.companyName}</TableCell>
              <TableCell>{t("companyNumber")}</TableCell>
              <TableCell align="right">{invoice.companyId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("invoiceDate")}</TableCell>
              <TableCell align="right">
                {dayjs(invoice.createdAt).locale("zh-tw").format()}
              </TableCell>
              <TableCell>{t("invoiceStatus")}</TableCell>
              <TableCell align="right">{t(invoice.status)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("invoiceAmount")}</TableCell>
              <TableCell align="right">{invoice.invoiceAmount}</TableCell>
              <TableCell>{t("vehicleCategory")}</TableCell>
              <TableCell align="right">{"QUEUE Member"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("buyerName")}</TableCell>
              <TableCell align="right">{invoice.buyerName}</TableCell>
              <TableCell>{t("carrierId")}</TableCell>
              <TableCell align="right">{invoice.carrierId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("buyerId")}</TableCell>
              <TableCell align="right">{invoice.buyerId}</TableCell>
              <TableCell>{t("donationCode")}</TableCell>
              <TableCell align="right">{invoice.donation}</TableCell>
            </TableRow>
          </Table>
          <Box sx={{ height: 30 }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("product")}</TableCell>
                <TableCell align="right">{t("quantity")}</TableCell>
                <TableCell align="right">{t("unitPrice")}</TableCell>
                <TableCell align="right">{t("totalPrice")}</TableCell>
              </TableRow>
            </TableHead>
            {invoice.items.map((item: any) => {
              return (
                <TableRow>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">{item.quantity}</TableCell>
                  <TableCell align="right">{item.unitPrice}</TableCell>
                  <TableCell align="right">{item.totalPrice}</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </CardContent>
        <CardActions>
          <Button onClick={() => setInvoice(null)}>{t("back")}</Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <Box>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={t("searchInvoice")} />
          <CardContent>
            <Stack spacing={3}>
              <Stack direction="row" spacing={3}>
                <TextField
                  inputProps={{ maxLength: 3 }}
                  label={t("chineseYear")}
                  {...register("chineseYear", {
                    required: "Required",
                    maxLength: 3,
                  })}
                  error={!!errors?.chineseYear}
                  helperText={errors?.chineseYear?.message}
                />
                <FormControl>
                  <InputLabel>{t("taxPeriod")}</InputLabel>
                  <Controller
                    name="taxPeriod"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select {...field} label="Tax period">
                          <MenuItem value={"0"}>01/02</MenuItem>
                          <MenuItem value={"1"}>03/04</MenuItem>
                          <MenuItem value={"2"}>05/06</MenuItem>
                          <MenuItem value={"3"}>07/08</MenuItem>
                          <MenuItem value={"4"}>09/10</MenuItem>
                          <MenuItem value={"5"}>11/12</MenuItem>
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Stack>
              <TextField
                label={t("invoiceNumber")}
                {...register("invoiceNumber", {
                  maxLength: { value: 10, message: "Max 10" },
                  required: "Required",
                  pattern: {
                    value: /[a-zA-Z]{2}\d{8}/,
                    message: "Invalid format",
                  },
                })}
                error={!!errors?.invoiceNumber}
                helperText={errors?.invoiceNumber?.message}
              />
              <TextField
                label={t("randomNumber")}
                {...register("randomNumber", {
                  maxLength: {
                    value: 4,
                    message: "Max 4",
                  },
                  pattern: {
                    value: /[0-9][0-9][0-9][0-9]/,
                    message: "Invalid format",
                  },
                })}
                placeholder="XXXX"
                error={!!errors?.randomNumber}
                helperText={errors?.randomNumber?.message}
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Button startIcon={<FiSearch />} type="submit">
              {t("search")}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Box>
  );
};
