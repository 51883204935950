import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Login } from "./Login";
import { SearchInvoice } from "./SearchInvoice";
import { Connect } from "./Connect";
import { NotFound } from "./NotFound";
import { Box, Container } from "@mui/material";
import { NavBar } from "./Navbar";

export const Router: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BrowserRouter>
        <NavBar />
        <Container maxWidth="md" sx={{ marginTop: 3 }}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchInvoice />} />
            <Route
              path="/search/:chineseYear/:taxPeriod/:invoiceNumber/:randomNumber"
              element={<SearchInvoice />}
            />
            <Route path="/connect" element={<Connect />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </Box>
  );
};
