import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { auth, functions } from "../firebase";
import { useAuthUser } from "@react-query-firebase/auth";
import { httpsCallable } from "firebase/functions";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { signOut } from "@firebase/auth";
import { useTranslation } from "react-i18next";

const handleGetSignature = async (
  requestToken: string,
  functions: any,
  setSignature: any,
  setCardId: any
) => {
  const callConnect = httpsCallable(functions, "invoiceConnectMembership");

  const response = (await callConnect({ token: requestToken })) as any;
  const { signature, cardId } = response.data;

  setSignature(signature);
  setCardId(cardId);
};

export const Connect: React.FC = () => {
  const location = useLocation();
  const query = queryString.parseUrl(location.search).query;

  const [signature, setSignature] = useState("");
  const [cardId, setCardId] = useState("");

  const user = useAuthUser(["user"], auth);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const tokenParam = query.token as string;

  useEffect(() => {
    console.log({ token: tokenParam });
    if (
      !user.isLoading &&
      !user.data &&
      tokenParam &&
      typeof tokenParam === "string"
    ) {
      console.log("setItem", tokenParam);
      window.localStorage.setItem("invoiceToken", tokenParam);
      navigate("/login");
    }
  }, [query, user, navigate, tokenParam]);

  console.log({ tokenParam });

  useEffect(() => {
    if (user.data && tokenParam) {
      console.log("getSignature");
      handleGetSignature(tokenParam, functions, setSignature, setCardId);
      window.localStorage.removeItem("invoiceToken");
    }
  }, [location, user, tokenParam]);

  if (user.isLoading) {
    return null;
  }

  if (signature && cardId && user.data) {
    return (
      <div>
        <form
          action="https://wwwtest.einvoice.nat.gov.tw/APCONSUMER/BTC101I/"
          method="POST"
        >
          <input type="hidden" id="card_ban" name="card_ban" value="52759308" />
          <input type="hidden" id="card_no1" name="card_no1" value={cardId} />
          <input type="hidden" id="card_no2" name="card_no2" value={cardId} />
          <input
            type="hidden"
            id="card_type"
            name="card_type"
            value="RUoxNTI3"
          />
          {tokenParam && (
            <input type="hidden" id="token" name="token" value={tokenParam} />
          )}
          <input
            type="hidden"
            id="signature"
            name="signature"
            value={signature}
          />
          <Card>
            <CardContent>
              <p>{t("confirmConnecting", { email: user.data.email })}</p>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="warning"
                onClick={() => {
                  signOut(auth);
                }}
              >
                {t("back")}
              </Button>
              <Button type="submit" variant="outlined" fullWidth>
                {t("confirm")}
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }

  return null;
};
