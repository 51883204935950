import React from "react";
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FiHome, FiChevronDown, FiUser } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const user = useAuthUser(["user"], auth);

  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Button
          onClick={() => navigate("/")}
          color="inherit"
          startIcon={<FiHome />}
        >
        </Button>
        <Typography component="div" sx={{ flexGrow: 1 }}>
        QUEUE
        </Typography>
        <Button onClick={() => i18n.changeLanguage("en")} color="inherit">
          EN
        </Button>
        <Button color="inherit" onClick={() => i18n.changeLanguage("zh-TW")}>
          中文
        </Button>
        {user.data ? (
          <div>
            <Button
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
              endIcon={<FiUser />}
            >
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Change email</MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleClose();
                  signOut(auth);
                }}
              >
                Sign out
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Button color="inherit">Login</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
